// external
import React from 'react'
import { graphql } from 'gatsby'
import sortBy from 'lodash.sortby'

// internal
import SEO from '../components/SEO'
import { PageLayout } from '../components/layout'
import LinkButton from '../components/LinkButton'
import FeaturedGrid from '../components/FeaturedGrid'
import PageContent from '../old/pages/Home'

export default ({ data }) => {
  const { hero, stations, genres } = extractData(data)
  const header = {
    lightOnDark: true,
    barStyle: 'overlay',
    background: {
      image: hero,
      withOverlay: false
    },
    children: <HeaderContent stations={stations} genres={genres} />
  }
  return (
    <PageLayout
      title={"The World's Audio"}
      subtitle={'Internet radio programmed by real people, not an algorithm.'}
      header={header}
    >
      <SEO title={'Broadcast and Listen to Internet Radio'} />
      <PageContent />
    </PageLayout>
  )
}

function extractData(data) {
  const genreImages = data.allGenreImage.edges.map(edge => edge.node)
  const featuredGenres = data.allFeaturedGenresJson.edges.map(edge => ({
    ...edge.node,
    imagePath: genreImages.find(image => image.name.includes(edge.node.genreID))
      .childImageSharp.fixed
  }))
  const featuredStations = data.allFeaturedStation.edges.map(edge => edge.node)
  // use lodash `sortBy` since JS `sort` is not guaranteed to be stable
  // stability is necessary to render the same order during SSR and CSR
  const sortedStations = sortBy(featuredStations, [
    s => s.fields.isCurated,
    s => s.fields.sortOrder,
    s => s.mount_id
  ])
  return {
    hero: data.hero.edges[0].node.childImageSharp.fluid,
    stations: sortedStations,
    genres: featuredGenres
  }
}

const HeaderContent = ({ stations, genres }) => (
  <React.Fragment>
    <FeaturedGrid stations={stations} genres={genres} />
    <LinkButton to={'/listen'} color={'blue'}>
      Discover More
    </LinkButton>
  </React.Fragment>
)

export const query = graphql`
  query {
    allFeaturedGenresJson {
      edges {
        node {
          url
          genreID
          label
        }
      }
    }
    allFeaturedStation: allStation(
      filter: { fields: { isFeatured: { eq: true } } }
    ) {
      edges {
        node {
          mount_id
          logo
          station_name
          fields {
            url
            isCurated
            sortOrder
            logoFile {
              childImageSharp {
                fixed(
                  width: 100
                  height: 100
                  quality: 100
                  cropFocus: CENTER
                ) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
              publicURL
            }
          }
        }
      }
    }
    allGenreImage: allFile(
      filter: { sourceInstanceName: { eq: "genreImage" } }
    ) {
      edges {
        node {
          name
          publicURL
          childImageSharp {
            fixed(width: 216, quality: 100) {
              ...GatsbyImageSharpFixed_noBase64
            }
          }
        }
      }
    }
    hero: allFile(filter: { relativePath: { eq: "hero-home.png" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2880, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
