import React from 'react'
import styled from 'styled-components'

import PlayableStationLogo from './PlayableStationLogo'
import GenreTile from './GenreTile'

export default ({ stations, genres }) => {
  const gridUnitSize = '100px'
  return (
    <Grid>
      <React.Fragment>
        {stations.slice(0, 16).map((station, index) => (
          <SquareGridItem key={index}>
            <PlayableStationLogo
              stationID={station.mount_id}
              stationName={station.station_name}
              stationLogoURL={getLogo(station)}
              size={gridUnitSize}
              stationURL={station.fields.url}
            />
          </SquareGridItem>
        ))}
        {genres.map((genre, index) => (
          <RectangularGridItem key={index} index={index}>
            <GenreTile
              url={genre.url}
              image={genre.imagePath}
              label={genre.label}
              height={gridUnitSize}
            />
          </RectangularGridItem>
        ))}
        {stations.slice(16, 32).map((station, index) => (
          <SquareGridItem key={index}>
            <PlayableStationLogo
              stationID={station.mount_id}
              stationName={station.station_name}
              stationLogoURL={getLogo(station)}
              stationURL={station.fields.url}
              size={gridUnitSize}
            />
          </SquareGridItem>
        ))}
      </React.Fragment>
    </Grid>
  )
}

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 100px);
  column-gap: 1rem;
  row-gap: 1rem;
  max-height: calc(500px + 4rem);
  overflow: hidden;
  @media (min-width: 350px) {
    grid-template-columns: repeat(3, 100px);
  }
  @media (min-width: 500px) {
    grid-template-columns: repeat(4, 100px);
  }
  @media (min-width: 800px) {
    grid-template-columns: repeat(6, 100px);
  }
  @media (min-width: 1200px) {
    grid-template-columns: repeat(8, 100px);
  }
`

const SquareGridItem = styled.div``

const RectangularGridItem = styled.div`
  grid-column-start: ${props => 1 + 2 * props.index};
  grid-column-end: ${props => 3 + 2 * props.index};
  font-size: 1.5rem;
  font-weight: 800;
  color: #fff;
  & img {
    position: absolute;
  }
  @media (max-width: 500px) {
    display: none;
  }
`

function getLogo(station) {
  if (station.fields.logoFile && station.fields.logoFile.childImageSharp) {
    return station.fields.logoFile.childImageSharp
  } else {
    return station.logo
  }
}
