import React from 'react'
import { Link } from 'gatsby'
import GatsbyImage from 'gatsby-image'
import styled from 'styled-components'

export default ({ url, label, image, height }) => (
  <Tile to={url} height={height}>
    <StyledGatsbyImage
      fixed={image}
      alt={label}
      critical={true}
      fadeIn={false}
    />
    <GenreName>{label}</GenreName>
  </Tile>
)

const Tile = styled(Link)`
  background-color: #1286e9;
  height: ${props => props.height};
  border-radius: 4px;
  text-decoration: none;
  font-family: 'Muli', sans-serif;
  font-size: 1.5rem;
  font-weight: 800;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  &::after {
    background-color: rgba(0, 0, 0, 0.4);
    content: '';
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: background-color 200ms ease;
  }
  &:hover ::after {
    background-color: rgba(0, 0, 0, 0.6);
  }
`

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 100%;
`

const GenreName = styled.span`
  position: absolute;
  z-index: 1;
`
